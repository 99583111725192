import * as React from "react"
import classNames from "classnames"
import { SectionHeader } from "../SectionHeader"
import Image from "gatsby-image"
import {
  card,
  body,
  footer as footerClass,
  cardContainer,
  imgBackground,
} from "./Card.module.css"
import { relative } from "../utils.module.css"

export const Card = ({
  title,
  subtitle,
  logo,
  background,
  footer,
  description,
  className,
  ...props
}) => {
  return (
    <div className={classNames(card, relative, className)} {...props}>
      {background ? (
        <Image fluid={background} className={imgBackground} />
      ) : null}
      <div className={`${cardContainer} ${relative}`}>
        <SectionHeader
          title={title}
          subtitle={subtitle}
          as="div"
          asTitle="h3"
        />

        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className={body}
        />

        <div className={footerClass}>{footer}</div>
      </div>
    </div>
  )
}
