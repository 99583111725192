import * as React from "react"
import { Base } from "../components/Base"
import classNames from "classnames"
import { button, full, narrow as narrowStyle } from "./RoundedButton.module.css"

export const RoundedButton = ({
  children,
  as = "button",
  variation = "full",
  narrow,
  className,
  ...props
}) => {
  const classes = classNames(
    {
      [full]: variation === "full",
      [narrowStyle]: narrow,
    },
    button,
    className
  )

  return (
    <Base as={as} className={classes} {...props}>
      {children}
    </Base>
  )
}
