import * as React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import classNames from "classnames"
import {
  card,
  header,
  imageWrapper,
  image,
  title as titleClass,
  category as categoryClass,
  overlayLink,
} from "./BlogPostCard.module.css"
import { relative } from "../utils.module.css"

export const BlogCard = ({
  title,
  slug,
  category,
  excerpt,
  teaserImage,
  className,
}) => {
  return (
    <div className={classNames(card, relative, className)}>
      <div className={imageWrapper} style={{ maxWidth: teaserImage.width }}>
        <Image className={image} fixed={teaserImage} />
      </div>
      <div className={header}>
        <span className={categoryClass}>{category}</span>
        <h3 className={titleClass}>
          <Link to={slug}>{title}</Link>
        </h3>
      </div>

      <p>{excerpt}</p>
      <Link to={slug} className={overlayLink} aria-hidden={true} tabIndex={-1}>
        {title}
      </Link>
    </div>
  )
}
