export function cleanSrcSet(fluid) {
  const srcSetChunks = fluid.srcSet.split(",")
  srcSetChunks.pop()
  fluid.srcSet = srcSetChunks.join(",")
  if (fluid.srcSetWebp) {
    const srcSetWebPChunks = fluid.srcSetWebp.split(",")
    srcSetWebPChunks.pop()
    fluid.srcSetWebp = srcSetWebPChunks.join(",")
  }

  return fluid
}
