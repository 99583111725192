import * as React from "react"
import classNames from "classnames"
import { RoundedButton } from "./RoundedButton"
import { Arrow } from "./Icons/index"
import { headlineArrow, headlineButton } from "./HeadlineButton.module.css"
import { marginLeft, inlineBlock } from "./utils.module.css"

export const HeadlineButton = ({ children, className, ...props }) => {
  return (
    <RoundedButton
      className={classNames(headlineButton, inlineBlock, className)}
      {...props}
    >
      {children}{" "}
      <span className={`${marginLeft} ${inlineBlock} ${headlineArrow}`}>
        <Arrow />
      </span>
    </RoundedButton>
  )
}
